.li-button{
    font-weight: bold;
    border-width: 1px;
    border-style: solid;
    cursor: pointer;
    margin: 0;
    overflow: visible;
    text-decoration: none !important;
    text-align: center;
    width: auto;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    padding: 0 13px;
    height: 31px;
    line-height: 31px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 13px;
    color: #008cc9;
    background-color: transparent;
    border-color: #008cc9;
    white-space: nowrap;
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    zoom: 1;
    *display: inline;
    vertical-align: middle;
}