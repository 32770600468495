/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.table-responsive {
  height: 100%;
  overflow: auto;
}

@media (min-width:801px){
  .responsive-table-text{
     font-size: 14px;
  }
}


@media (max-width:800px){
  .responsive-table-text{
    font-size: 12px;
  }
}


@media (min-width:950px){
    .main-icon{
        margin-left: 5px;
        margin-top: 10px;
        width: 200px;
        height: 65px;
    }
}
@media (max-width:949px){
    .main-icon{
        margin-left: 5px;
        margin-top: 10px;
        width: 150px;
        height: 45px;
    }
}
/* @media (max-width:800px){
    .main-icon{
        margin-left: 5px;
        margin-top: 10px;
        width: 120px;
        height: 40px;
        
    }
} */
@media (max-width:700px){
    .main-icon{
        margin-left: 5px;
        margin-top: 10px;
        width: 150px;
        height: 45px;
    }
}

.mainBackgroundColor{
    background-color: #3C90CE;
}

.btn-grad {background-image: linear-gradient(45deg, rgba(91,171,230,1) 0%, rgba(65,133,184,1) 100%)}
.btn-grad {
   cursor: pointer;
   max-width: 200px;
   padding: 5px 5px;
   text-align: center;
   font-size: 17px;
   font-weight: 500;
   transition: 0.5s;
   background-size: 200% auto;
   color: rgb(0, 0, 0);            
   box-shadow: 0 0 20px #eee;
   border-radius: 10px;
   display: block;
   text-decoration: none;
 }

 .btn-grad:hover {
   background-position: right center; /* change the direction of the change here */
   color: #fff;
   text-decoration: none;
 }
@media (min-width: 1201px) {
  .split-bar {
    width: 40%;
  }
}

@media (max-width: 1200px) {
    .split-bar {
      width: 60%;
    }
  }

@media (max-width: 800px) {
  .split-bar {
    width: 90%;
  }
}

.li-button{
    font-weight: bold;
    border-width: 1px;
    border-style: solid;
    cursor: pointer;
    margin: 0;
    overflow: visible;
    text-decoration: none !important;
    text-align: center;
    width: auto;
    border-radius: 2px;
    padding: 0 13px;
    height: 31px;
    line-height: 31px;
    box-sizing: border-box;
    font-size: 13px;
    color: #008cc9;
    background-color: transparent;
    border-color: #008cc9;
    white-space: nowrap;
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    zoom: 1;
    *display: inline;
    vertical-align: middle;
}
