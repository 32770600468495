.mainBackgroundColor{
    background-color: #3C90CE;
}

.btn-grad {background-image: linear-gradient(45deg, rgba(91,171,230,1) 0%, rgba(65,133,184,1) 100%)}
.btn-grad {
   cursor: pointer;
   max-width: 200px;
   padding: 5px 5px;
   text-align: center;
   font-size: 17px;
   font-weight: 500;
   transition: 0.5s;
   background-size: 200% auto;
   color: rgb(0, 0, 0);            
   box-shadow: 0 0 20px #eee;
   border-radius: 10px;
   display: block;
   text-decoration: none;
 }

 .btn-grad:hover {
   background-position: right center; /* change the direction of the change here */
   color: #fff;
   text-decoration: none;
 }