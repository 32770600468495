
@media (min-width:950px){
    .main-icon{
        margin-left: 5px;
        margin-top: 10px;
        width: 200px;
        height: 65px;
    }
}
@media (max-width:949px){
    .main-icon{
        margin-left: 5px;
        margin-top: 10px;
        width: 150px;
        height: 45px;
    }
}
/* @media (max-width:800px){
    .main-icon{
        margin-left: 5px;
        margin-top: 10px;
        width: 120px;
        height: 40px;
        
    }
} */
@media (max-width:700px){
    .main-icon{
        margin-left: 5px;
        margin-top: 10px;
        width: 150px;
        height: 45px;
    }
}
